



























































































import { Component, Emit, Vue } from 'vue-property-decorator'
import ModalDialog from '@/components/ModalDialog.vue'

// 選択項目
interface SelectOption {
  text: string,
  value: string,
}

@Component({
  components: {
    ModalDialog
  }
})
export default class Contact extends Vue {

  // APIのエンドポイント
  baseURL: string = process.env.NODE_ENV === 'production' ? 'https://' + location.hostname : '/api'

  // モーダルダイアログ
  modal: {
    title: string            // タイトル
    message: string          // メッセージ
    complete: boolean        // 完了フラグ
  } = {
    title: '',
    message: '',
    complete: false,
  }

  // 入力データ
  form: {
    name: string,            // お名前
    email: string,           // メールアドレス
    emailConfirm: string,    // メールアドレス(確認)
    triggers: string[],      // きっかけ(複数選択)
    category: string,        // お問い合わせ種類
    personConcerned: string, // 体験、入会希望の場合の該当者
    content: string,         // お問い合わせ内容
  } = {
    name: '',
    email: '',
    emailConfirm: '',
    triggers: [],
    category: 'null',
    personConcerned: 'ご本人',
    content: '',
  }

  // 選択項目
  triggerOptions: SelectOption[] = [
    { text: 'インターネット検索', value: 'インターネット検索' },
    { text: '紹介', value: '紹介' },
    { text: '口コミ', value: '口コミ' },
    { text: '広告', value: '広告' },
    { text: 'その他', value: 'その他' },
  ]
  categoryOptions: SelectOption[] = [
    { text: '見学希望', value: '見学希望' },
    { text: '体験希望', value: '体験希望' },
    { text: '入会希望', value: '入会希望' },
    { text: 'その他', value: 'その他' },
  ]
  personConcernedOptions: SelectOption[] = [
    { text: 'ご本人', value: 'ご本人' },
    { text: 'お子様', value: 'お子様' },
    { text: 'その他', value: 'その他' },
  ]

  // アクティブなページを設定
  @Emit()
  public activate() {
    return 'contact'
  }
  mounted() {
    this.activate()
  }

  // フィールドのバリデーション
  validateField(props: any): boolean | null {
    return (props.pristine || props.valid) ? null : false
  }

  // メール送信
  sendMail() {
    let params = new URLSearchParams();

    // パラメータをセットする。
    params.append('name', this.form.name)
    params.append('email', this.form.email)
    params.append('category', this.form.category)
    params.append('personConcerned', this.form.personConcerned)
    params.append('content', this.form.content)
    for (let trigger of this.form.triggers) {
      params.append('triggers[]', trigger)
    }

    // 送信する。
    Vue.axios
       .post(`${this.baseURL}/contact/contact.php`, params)
       .then(response => {
         this.modal.message = response.data
         this.modal.complete = true
       })
       .catch(error => {
         this.modal.message = error.response.data
         this.modal.complete = true
       })
    this.showModalDialog('送信中')
  }

  // モーダルダイアログ表示
  private showModalDialog(title: string): void {
    this.modal.title = title
    this.modal.message = ''
    this.modal.complete = false
    this.$bvModal.show("modal-dialog")
  }

  // モーダルダイアログを閉じるボタンが押されたときの処理
  closeRequest(): void {

    // トップページに移動する。
    this.$router.replace('/')
  }

}
