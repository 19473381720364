















































































import { Component, Vue } from 'vue-property-decorator'
import { Config } from '@/types/config'

// ページ内リンク、ページ情報
interface AnchorInfo {
  [id: string]: string,
}
interface PageInfo {
  [name: string]: {
    url: string,
    title: string,
    active: boolean,
    anchors?: AnchorInfo,
  }
}

@Component
export default class App extends Vue {

  // システム設定
  config: Config = {
    last_update: '2022-02-15',
  }

  // サイトマップ
  pages: PageInfo = {
    home: {
      url: '/', title: 'トップページ', active: true,
      anchors: {
        about: '上野山テニススクールについて',
        course: 'クラス紹介',
        access: 'アクセス',
      },
    },
    information: { url: '/information', title: 'お知らせ', active: false },
    staff: { url: '/staff', title: 'スタッフ紹介', active: false },
    price: { url: '/price', title: '料金・スケジュール', active: false },
    contact: { url: '/contact', title: 'お問い合わせ', active: false },
  }

  // アクティブなページを設定
  setActive(pageName: string) {
    for (let key in this.pages) {
      this.pages[key].active = (key === pageName)
    }
  }

}
