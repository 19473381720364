// -*- coding: utf-8; mode: typescript -*-
// Time-stamp: <2023-05-11 16:42:48 sumita>

// Copyright (C) 2020, Sumita Planning, All rights reserved.

//
// 2023-05-11 Toru Sumita <sumita@sumita-planning.jp>
//   * GoogleアナリティクスUA→GA4に移行した。
//
// 2022-02-15 Toru Sumita <sumita@sumita-planning.jp>
//   * public/sitemap.txtのURLをhttp://www.uenoyama-ts.com → https://uenoyama-ts.comに変更した。
//   * お問い合わせページのAPIのエンドポイントをhttp: → https://に変更した。
//   * PHPMailerを6.1.4 → 6.5.3にアップデートした。
//
// 2021-04-14 Toru Sumita <sumita@sumita-planning.jp>
//   * コーチのつぶやき(Tweet)ページをお知らせ(Information)ページに変更し、トップページの次に移動した。
//   * トップページの新着情報(News)部を削除し、内容をお知らせ(Information)ページに追加した。
//

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Bootstrap, BootstrapVue
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// Font Awesome, vue-fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far,fab)

// <font-awesome-icon :icon="['fas', 'sign-in-alt']" />でアイコンを表示
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

// <i class="fas fa-sign-in-alt"></i>でアイコンを表示
// import { dom } from '@fortawesome/fontawesome-svg-core'
// dom.watch()

// axios, vue-axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// VeeValidate
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// vue-scrollto
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  offset: -70,                                  // ナビゲーションバーの高さ
})

// 日本語化
import ja from 'vee-validate/dist/locale/ja'
localize('ja', ja)

// 全てのバリデーションルール
import * as rules from 'vee-validate/dist/rules'
for (let rule in rules) {
  extend(rule, (rules as any)[rule])
}

// 起動時のヒントを非表示
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
