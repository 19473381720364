


















































































import { Component, Emit, Vue } from 'vue-property-decorator'

@Component
export default class Staff extends Vue {

  // 個人データ
  personalDatas: {
    imageFile: string,            // 写真
    lastName: string,             // 姓
    firstName: string,            // 名
    lastNameReading: string,      // 姓よみ
    firstNameReading: string,     // 名よみ
    resume?: string,              // 履歴書ファイル
    assignedCourses?: string[],   // 担当クラス
    birthDate?: string,           // 生年月日
    bloodType?: string,           // 血液型
    licenses?: string[],          // 資格
    overallRecords?: string[],    // 戦績
    currentActivities?: string[], // 現在の活動
    pastActivities?: string[],    // 過去の活動
    greetings?: string[],         // ひとこと
  }[] = [
    {
      imageFile: require('@/assets/staff/uenoyama-hiroki.jpg'),
      lastName: '上野山',
      firstName: '博城',
      lastNameReading: 'うえのやま',
      firstNameReading: 'ひろき',
      resume: '/staff/履歴書-上野山博城.pdf',
      assignedCourses: [
        '一般', 'ジュニア', 'アカデミー',
      ],
      birthDate: '1975年8月25日',
      bloodType: 'B型',
      licenses: [
        '元 財団法人テニス協会プロフェッショナルレジスタードプレイヤー',
      ],
      overallRecords: [
        '全日本選手権 シングルスベスト32/ダブルスベスト16',
        '国体(広島、大阪)静岡県代表 4位(2回)',
        '北信越選手権 シングルス優勝(2回)/ダブルス優勝',
        '全国実業団選手権優勝',
        '県大会(春、秋、国体) 和歌山、静岡、群馬 シングルス優勝(10回以上)/ダブルス優勝(4回)',
      ],
      currentActivities: [
        '群馬県ジュニア委員',
      ],
      pastActivities: [
        '2015〜2016年度 群馬県大会運営委員',
        '2017〜2018年度 群馬県ジュニア委員長',
      ],
      greetings: [
        'テニスを生涯スポーツに。',
        '一般の方々には生涯テニスを楽しめるような基本を身につけ美しいプレーを。あなたの心と身体の健康をお手伝いします。',
        '子供達には健全な成長を基本理念として体力・忍耐力・規律・ルール・マナーを身につけさせ、努力した事によって勝つことの喜び・自信・達成感・楽しさを知ってもらいたいと思います。',
      ],
    },
    {
      imageFile: require('@/assets/staff/nakahara-makiko.jpg'),
      lastName: '中原',
      firstName: '牧子',
      lastNameReading: 'なかはら',
      firstNameReading: 'まきこ',
      assignedCourses: [
        'アカデミー(アシスタント)', 'キッズ',
      ],
      birthDate: '1968年12月19日',
      licenses: [
        '文科省公認コーチ',
      ],
      overallRecords: [
        '2003、2004年国民体育大会群馬県代表',
        '2011年全日本ベテラン選手権40歳シングルス準優勝',
        '2013年毎日テニス選手権45歳シングルス優勝',
      ],
      greetings: [
        '全てのことに感謝。',
        '皆さまのお役にたてるよう日々精進します。どうぞよろしくお願いいたします。',
      ],
    },
    {
      imageFile: require('@/assets/staff/iizuka-umito.jpg'),
      lastName: '飯塚',
      firstName: '海斗',
      lastNameReading: 'いいづか',
      firstNameReading: 'うみと',
      assignedCourses: [
        'アカデミー(アシスタント)', 'ヒッティング',
      ],
      birthDate: '1986年6月9日',
      bloodType: 'O型',
      overallRecords: [
        '群馬県A級ダブルス優勝',
        '群馬県国体予選3位',
        'ダンロップスリクソンテニストーナメント関東決勝ダブルス優勝',
      ],
      greetings: [
        'テニスを楽しんで! でも練習は真剣に!',
        '大きい夢を持って諦めずに挑戦し続けましょう!',
      ],
    },
    {
      imageFile: require('@/assets/staff/kobayashi-asuka.jpg'),
      lastName: '小林',
      firstName: '明日香',
      lastNameReading: 'こばやし',
      firstNameReading: 'あすか',
      assignedCourses: [
        'アカデミー(アシスタント)',
      ],
      birthDate: '1993年6月18日',
      licenses: [
        '柔道整復師',
        'スポーツ医学検定2級',
      ],
      overallRecords: [
        'インターハイ団体戦ベスト16',
        '関東ジュニア出場',
        '関東学生本戦出場',
        '群馬県A級ダブルス優勝',
        '群馬県A級シングルス3位',
      ],
      greetings: [
        '一緒に楽しくテニスをしましょう!!',
      ],
    },
    {
      imageFile: require('@/assets/staff/uenoyama-ryuto.jpg'),
      lastName: '上野山',
      firstName: '竜人',
      lastNameReading: 'うえのやま',
      firstNameReading: 'りゅうと',
      assignedCourses: [
        'アカデミー(アシスタント)', 'ヒッティング',
      ],
      birthDate: '2000年3月9日',
      bloodType: 'B型',
      overallRecords: [
        '群馬県ジュニアダブルス優勝',
        '関東ジュニア出場',
        'インターハイ団体出場',
      ],
      greetings: [
        '一緒に楽しみながら一歩一歩上達しましょう。',
      ],
    },
    {
      imageFile: require('@/assets/staff/kobatake-mitsuru.jpg'),
      lastName: '小畑',
      firstName: '満',
      lastNameReading: 'こばたけ',
      firstNameReading: 'みつる',
      assignedCourses: [
        'フィジカルトレーニング',
      ],
      licenses: [
        'ハート接骨院院長 柔道整復師',
      ],
      currentActivities: [
        '上野山テニスクラブフィジカルトレーナー',
        'Jリーグ松本山雅FC 高橋諒選手パーソナルトレーナー',
        '群馬県立前橋工業高校男子新体操部チームトレーナー',
      ],
      pastActivities: [
        '群馬県内高校のサッカー部、硬式野球部、女子ソフトボール部、女子バスケートボール部のチームトレーナー',
        '競輪(S級)選手、女子硬式テニス(高校生)選手のパーソナルトレーナー',
        'など',
      ],
      greetings: [
        '多致のトレーナー経験を生かし、テニスプレーヤーに必要なトレーニングを指導させて頂きます。',
        'トレーニングというと「地味で辛い」というメージがありますが、できるだけ楽しく集中して行えるよう、トレーニング内容を工夫して取り組んでおります。',
      ],
    },
  ]

  // アクティブなページを設定
  @Emit()
  public activate() {
    return 'staff'
  }
  mounted() {
    this.activate()
  }

}
