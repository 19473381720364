































import { Component, Prop, Emit, Vue } from 'vue-property-decorator'

@Component
export default class ModalDialog extends Vue {
  @Prop({ default: '処理中' }) private title!: string    // タイトル
  @Prop({ default: '' }) private message!: string        // メッセージ
  @Prop({ default: false }) private complete!: boolean   // 完了フラグ

  // 閉じるボタンが押されたときの処理
  @Emit('close-request')
  closeRequest() {
    this.$bvModal.hide('modal-dialog')
  }

}

