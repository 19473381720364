// -*- coding: utf-8; mode: typescript -*-
// Time-stamp: <2020-01-05 13:16:20 sumita>

// Copyright (C) 2020, Sumita Planning, All rights reserved.

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
