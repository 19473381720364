import { render, staticRenderFns } from "./CourseBrief.vue?vue&type=template&id=78a01277&scoped=true&"
import script from "./CourseBrief.vue?vue&type=script&lang=ts&"
export * from "./CourseBrief.vue?vue&type=script&lang=ts&"
import style0 from "./CourseBrief.vue?vue&type=style&index=0&id=78a01277&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78a01277",
  null
  
)

export default component.exports