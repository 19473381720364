























import { Component, Vue } from 'vue-property-decorator'

 // 「について」データ
 interface AboutInfo {
   name: string               // 名称
   title?: string             // タイトル
   articles: string[]         // 記事
   imageFile: string          // 写真
 }

@Component
export default class About extends Vue {

   aboutInfos: AboutInfo[] = [
     {
       name: 'テニススクールについて',
       title: '気軽にテニスはじめませんか?',
       articles: [
         `元プロフェッショナルテニスプレイヤーの経験豊富なコーチがレベルに合わせて楽しく! 厳しく! 指導します。`,
         `<span class="text-danger">一般、ジュニア、キッズ ( パパ、ママ一緒でも大丈夫!! ) 各クラス無料体験やってます。<br />
          ラケット無料貸し出しあり。お気軽にお問い合わせ下さい。</span>`
       ],
       imageFile: require('@/assets/about/about-00.jpg'),
     }
   ]
}
