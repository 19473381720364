// -*- coding: utf-8; mode: typescript -*-
// Time-stamp: <2021-04-19 18:52:07 sumita>

// Copyright (C) 2020, Sumita Planning, All rights reserved.

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import CourseBrief from '@/components/CourseBrief.vue'
import Staff from '@/views/Staff.vue'
import Price from '@/views/Price.vue'
import Information from '@/views/Information.vue'
import Contact from '@/views/Contact.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes : [
    {
      // トップページ
      path: '/home',
      // デフォルトの子ルートに親ルートの名前を設定
      // name: 'home',
      component: Home,
      children: [
        { path: '', component: CourseBrief, name: 'home', props: true }, // デフォルトページ(ジュニア)
        { path: 'course/:target', component: CourseBrief, name: 'courseBrief', props: true },
      ],
    },
    {
      // お知らせ
      path: '/information',
      name: 'information',
      component: Information,
    },
    {
      // スタッフ紹介
      path: '/staff',
      name: 'staff',
      component: Staff,
    },
    {
      // 料金表、スケジュール
      path: '/price',
      name: 'price',
      component: Price,
    },
    {
      // お問い合わせ
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    {
      // その他: トップページにリダイレクト
      path: '*',
      redirect: '/home',
    },
  ],

  // スクロール
  //   https://router.vuejs.org/ja/guide/advanced/scroll-behavior.html
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      // 進む、戻るボタン
      return savedPosition
    }
    else if (to.hash) {
      // アンカーへスクロール
      return {
        selector: to.hash,
      }
    }
  },

})

export default router
