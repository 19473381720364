


















































import { Component, Emit, Vue } from 'vue-property-decorator'

// 記事データ
interface InformationInfo {
  date?: string              // 日付
  title: string              // タイトル
  url?: string               // URL
}

 // リンク
 interface LinkInfo {
   title: string             // タイトル
   url: string               // ファイル
 }

@Component
export default class Information extends Vue {

  informationInfos: InformationInfo[] = [
    {
      date: '2021年3月18日',
      title: '土曜日ジュニア初級クラス、春休み特別練習会',
      url: '/information/20203-新着情報.pdf',
    },
    {
      date: '2020年8月1日',
      title: '渋川校のご案内',
      url: '/information/渋川校.pdf',
    },
    {
      date: '2020年7月31日',
      title: '夏休みちびっ子応援特別企画のお知らせ',
      url: '/information/2020-夏休み特別企画.pdf',
    },
    {
      date: '2020年7月31日',
      title: '夏休み特別練習会を行います',
      url: '/information/2020-夏休み特別練習会.pdf',
    },
    {
      date: '2020年3月1日',
      title: '春のキャンペーン始まります',
      url: '/information/2020-spring-campaign.pdf',
    },
    {
      date: '2020年2月18日',
      title: 'サイトをリニューアルオープンしました',
    },
    {
      date: '2019年12月1日',
      title: 'ジュニアアカデミークラスが新設されました',
      url: '/information/junior-academy.pdf',
    },
    {
      date: '2019年8月17日',
      title: 'インスタグラム始めました',
      url: 'https://www.instagram.com/uenoyama_ts/',
    },
    {
      date: '2019年6月25日',
      title: 'キッズクラスが始まりました',
    },
    {
      title: '卒業生の進学先 (〜2020)',
      url: '/information/卒業生の進学先.pdf',
    },
    {
      title: '練習量と目標設定',
      url: '/information/練習量と目標設定.pdf',
    },
  ]

  linkInfos: LinkInfo[] = [
    {
      title: 'TENISH 日本最大級のテニス専門ポータルメディア',
      url: 'https://tennis-media.com'
    },
  ]

  // アクティブなページを設定
  @Emit()
  public activate() {
    return 'information'
  }

  mounted() {
    this.activate()
  }

}
