

























import { Component, Vue } from 'vue-property-decorator'

// 画像データ
interface ImageInfo {
  name: string               // 名称
  imageFile: string          // 写真
  caption?: string           // 説明文のヘッダ
  text?: string              // 説明文
}

@Component
export default class Top extends Vue {

  imageInfos: ImageInfo[] = [
    {
      name: '上野山コーチ',
      imageFile: require('@/assets/top/slide-00.jpg'),
      caption: '初心者大歓迎！',
      text: '元プロフェッショナルテニスプレイヤーの経験豊富なコーチがレベルに合わせて楽しく、厳しく、指導します',
    },
    {
      name: 'キッズクラス',
      imageFile: require('@/assets/top/slide-01.jpg'),
      caption: 'キッズクラス',
      text: 'ボール投げにチャレンジ',
    },
    {
      name: '集合写真',
      imageFile: require('@/assets/top/slide-02.jpg'),
    },
    {
      name: '集合写真',
      imageFile: require('@/assets/top/slide-03.jpg'),
      text: 'ドイツ人のレベンテのご好意で今日は選抜メンバーとも一緒にテニスをしました',
    },
    {
      name: '集合写真',
      imageFile: require('@/assets/top/slide-04.jpg'),
      text: 'ずっと一緒に居てくれて本当に本当にありがとう',
    },
  ]

}
