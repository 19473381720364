


















































import { Component, Emit, Vue } from 'vue-property-decorator'
import Top from '@/components/Top.vue'
import About from '@/components/About.vue'
import Access from '@/components/Access.vue'

@Component({
  components: {
    Top,
    About,
    Access,
  }
})
export default class Home extends Vue {

  // アクティブなページを設定
  @Emit()
  public activate() {
    return 'home'
  }
  mounted() {
    this.activate()
  }
}
