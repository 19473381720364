











































































import { Component, Prop, Vue } from 'vue-property-decorator'

// クラス概要データ
interface CourseBriefInfo {
  name: string               // 名称
  overview?: string          // 概要
  imageFile?: string         // 写真
  age?: string               // 対象年齢
  subclasses?: {             // 下位クラス
    name: string             //   名称
    overview?: string        //   概要
    imageFile?: string       //   写真
    age?: string             //   対象年齢
    classification?: string  //   クラス分けの基準
    description?: string     //   説明
    goal?: string            //   目標
  }[]
}
interface CourseBriefInfos {
  [target: string]: CourseBriefInfo
}

@Component
export default class CourseBrief extends Vue {
  @Prop({ default: 'junior' })
  private target!: string    // 対象

  // クラス概要データ
  courseBriefs: CourseBriefInfos = {
    junior: {
      name: 'ジュニア',
      overview: `2019年12月からジュニアアカデミークラスを新設し、レッスン形態が大幅リニューアルしました。<br />
                 アカデミーと育成は練習時間が増え、沢山のプログラムが組めるようになりました。この機会に是非新しいレッスンプログラムを体験してみて下さい。`,
      imageFile: require('@/assets/course-brief/junior.jpg'),
      subclasses: [
        {
          name: 'ジュニア初級',
          overview: `まずは運動に親しみ、テニスに適した身体の動かし方を楽しく学びます。<br />
          基本の挨拶、フォームから試合のやり方まで丁寧に教えます。`,
          age: '小学生～中学生',
          classification: '始めてラケットを握る子から、ラリーが少しでき、試合に参加して1〜2回戦レベル',
          description: `テニスの基本のフォームと楽しさを学び、運動能力全般を身につけ、選手としての基礎を身につけるチーム。<br />
                        体を動かしてテニスを学ぶ。`,
          goal: `週1回: 2〜3年して試合の仕方を少しずつ覚える。<br />
                 週2〜3回: 低学年から続けていれば県大会10〜12歳以下で上位をめざせる。高学年からだと2〜3年で3〜4回戦進出をめざす。`,
          imageFile: require('@/assets/course-brief/junior-novice.jpg'),
        },
        {
          name: '中高生基本',
          overview: `中学、高校からテニスに興味を持ち、勉強や部活動とテニスを頑張りたいと考えている生徒向けに、テニスの基本を学ぶクラスを新設しました。
                     育成、アカデミークラスに向けて基礎を学び、育成参加OKが出れば並行して受講できます。<br />
                     (※ まだ生徒が少ないので細かく指導できます。)`,
          age: '中学生～高校生',
          classification: '始めてラケットを握る子から、ラリーが少しでき、試合に参加して1〜2回戦レベル',
          description: `テニスの基本のフォームを学び、育成、アカデミーに向けて基礎を学ぶ。<br />
                        勉強や部活動とテニスの両立をめざす。<br />
                        育成参加OKが出れば並行して受講できる。`,
          goal: `高体連本戦`,
          imageFile: require('@/assets/course-brief/junior-intermediate.jpg'),
        },
        {
          name: 'ジュニア育成',
          overview: `育成選手として勉強や部活動とテニスの両立をめざし、週3回以下でアカデミークラス受講も可能です。`,
          age: '小学校高学年～高校生',
          classification: '小学校県大会上位レベル以上',
          description: `勉強や部活動とテニスの両立をめざす。<br />
                        アカデミークラスと同時にレベル別に分かれ練習。`,
          goal: `関東大会出場`,
          imageFile: require('@/assets/course-brief/junior-advanced.jpg'),
        },
      ],
    },
    kids: {
      name: 'キッズ',
      subclasses: [
        {
          name: 'キッズ',
          overview: `あいさつ、列に並んで順番を守るという基本的な事から、走る、体を動かす、ラケットを握りテニスボールに慣れる等、
                     テニスの楽しさを知ってもらうクラスです。`,
          imageFile: require('@/assets/course-brief/kids.jpg'),
          age: '5歳～8歳くらいまで',
          classification: '始めてラケットを握る子',
          description: `まずは運動に親しみ、身体の動かし方を楽しく学びます。`,
          goal: '5歳から初めて9〜10歳の時には県チャンピオンをめざす。',
        },
      ],
    },
    academy: {
      name: 'ジュニアアカデミー',
      subclasses: [
        {
          name: 'ジュニアアカデミー',
          overview: `新たにジュニアアカデミークラスを開講することにより、今まで以上に結果を求めることはもちろん、
                     テニスを通じて努力や挑戦し続ける力を身につけ、勝つ事の喜び、自信、達成感、楽しさを知ってもらい、
                     引退後も夢や目標に向かって頑張れる人間形成を主題として、チャレンジし続ける環境を整えます。`,
          imageFile: require('@/assets/course-brief/academy.jpg'),
          age: '小学校高学年～高校生',
          classification: '小学校県大会上位レベル以上',
          description: `関東、全国大会はもちろん、世界での活躍を視野に入れたチーム。<br />
                        努力や挑戦し続けることの大切さ、人として引退後も夢や目標に向かって努力することのできる力を身につけさせます。
                        各学年、各レベルでの試合の選び方などもアドバイスします。`,
          goal: `関東、全国大会上位。世界での活躍を視野に入れていく。`,
        },
      ],
    },
    adult: {
      name: '一般',
      overview: `生涯スポーツとしてラケットの握り方から競技、勝利をめざす方まで、よりテニスが楽しめるように指導していきます。
                 ラケットを握った事がない方から市民大会等に挑戦中の方まで1から丁寧に指導します。`,
      imageFile: require('@/assets/course-brief/adult.jpg'),
      subclasses: [
        {
          name: '一般初心者',
          overview: `初心者クラスは少人数で基本フォームから試合のやり方まで丁寧に教えます。`,
          age: '中学生～大人',
          classification: 'これからテニスを始める方',
          description: `始めてテニスをする、または、基本的なショットの指導を受けたい方のクラス。`,
        },
        {
          name: '一般初中級',
          overview: `昼間からガッツリ運動したい、日焼けしたくない、仕事の関係で夜しか通えない、毎週は通えないからチケット制が良い、
                     等のご要望に答えて色々な時間帯のクラスがあります。詳しくはお問い合わせ下さい。`,
          age: '高校生～大人',
          classification: 'テニスをしたことがある方',
          description: `基本的なショットは身につけたがまだゲームを楽しめないという方に、更にテニスの楽しさを知ってもらうクラス。`,
        },
        {
          name: '一般中上級',
          age: '高校生～大人',
          classification: 'ゲームが楽しめる方',
          description: `基本的なショットをよりレベルアップし、ゲームが楽しめる方に、次にステップアップしてもらうためのクラス。`,
          imageFile: require('@/assets/course-brief/adult-intermediate.jpg'),
        },
        {
          name: '一般上級',
          overview: `学生時代はバリバリやっていたけどブランクのある方、現在も県大会でがんばっているけどさらに上をめざしたい方。
                     レッスンメニューは初中級よりハードに、戦術も考えた濃いメニューになっています。`,
          age: '中学生～大人',
          classification: '県B級男子以上のレベル',
          description: `試合に勝つための総合技術を磨き、各ショットの精度を高めて、県大会で勝ち進み上位をめざすクラス。`,
          imageFile: require('@/assets/course-brief/adult-advanced.jpg'),
        },
      ],
    },
    private: {
      name: 'プライベート',
      overview: `課題をどう解決するかを念頭に置き、より実戦に近い状態を作り上げて集中力を高めて練習します。`,
      subclasses: [
        {
          name: 'プライベートレッスン',
          overview: `高性能カメラで丁寧にフォームをチェックします。<br />
                     プライベートなら「ティーチング」、「コーチング」、「フィードバック」を一人一人としっかり対話し、
                     方向性のすり合わせをしながらより実力を向上させる事ができます。`,
          imageFile: require('@/assets/course-brief/private-lesson.jpg'),
        },
        {
          name: 'ヒッティング',
          overview: `ラリー練習する相手が欲しい方。
                     マンツーマンでコート1面を使えるので、ご要望に応じたラリー練習ができます。シングルスのマッチ練習も可能です。`,
          imageFile: require('@/assets/course-brief/private-hitting.jpg'),
        },
      ],
    },
    training: {
      name: 'フィジカルトレーニング',
      subclasses: [
        {
          name: 'フィジカルトレーニング',
          overview: `フィジカルトレーナーによる専門的なトレーニングを受けられます。
                     多彩なメニューで楽しくバランス良く筋力、走力をアップ。`,
          imageFile: require('@/assets/course-brief/physical-training.jpg'),
          age: '10歳～高校生',
        },
      ],
    }
  }

  get brief() {
    return this.courseBriefs[this.target]
  }

}
