









































































import { Component, Emit, Vue } from 'vue-property-decorator'

// スケジュールデータ
interface ScheduleInfo {
  title: string              // タイトル
  file: string               // ファイル
}

// 料金データ
interface PriceInfo {
  [course: string]: {                          // クラス
    title: string                              // タイトル
    time?: string                              // 時間
    initialFee?: string                        // 入金会
    membershipFee?: {                          // 月会費
      [title: string]: string
    }
    privateLessonFee?: string                  // プライベートレッスン
    hittingLessonFee?: string                  // ヒッティング
    note?: string                              // 備考
  }
}

interface PriceInfos {
  [target: string]: {                          // 対象(ジュニア、一般)
    title: string                              // タイトル
    notes?: string[]                           // 備考
    prices: PriceInfo                          // 料金データ
  }
}

@Component
export default class Price extends Vue {

  // アクティブなページを設定
  @Emit()
  public activate() {
    return 'price'
  }
  mounted() {
    this.activate()
  }

  // スケジュールデータ
  scheduleInfos: ScheduleInfo[] = [
    {
      title: '週間スケジュール',
      file: '/schedule/schedule.pdf',
    },
  ]

  // 料金データ
  priceInfos: PriceInfos = {
   junior: {                                  // ジュニア
     title: 'ジュニアクラス料金表 (消費税別)',
     notes: [
       'ボール代として1、4、7、10月に一人1,000円頂きます。',
       '第5週はお休みです。プライベートレッスンおよびヒッティングは受け付けます。',
       '兄弟、姉妹割り引きあります。詳細はお問い合わせください。',
     ],
     prices: {
       kids: {
         title: 'キッズ',
         time: '45分',
         initialFee: '5,000円',
         membershipFee: {
           '週1回': '5,000円',
         },
         privateLessonFee: '30分 3,000円',
       },
       novice: {
         title: 'ジュニア初級',
         time: '1時間または1時間20分',
         initialFee: '5,000円',
         membershipFee: {
           '週1回 1時間': '6,000円',
           '週1回 1時間20分': '7,000円',
           '週2回': '12,000円',
           '週3回': '15,000円',
         },
         privateLessonFee: '30分 3,000円',
         hittingLessonFee: '30分 2,000円',
       },
       intermediate: {
         title: '中高生基本',
         time: '1時間',
         initialFee: '5,000円',
         membershipFee: {
           '週1回': '7,000円',
           '週2〜3回': '育成参加OKが出れば育成と組み合わせる。',
         },
         privateLessonFee: '1時間 6,000円',
         hittingLessonFee: '1時間 3,500円',
       },
       advanced: {
         title: 'ジュニア育成',
         time: '2時間30分',
         initialFee: '10,000円',
         membershipFee: {
           '週1回': '11,000円',
           '週2回': '21,000円',
           '週3回': '30,000円',
         },
         privateLessonFee: '1時間 6,000円',
         hittingLessonFee: '1時間 3,500円',
       },
       academy: {
         title: 'ジュニアアカデミー',
         time: '2時間30分',
         initialFee: '30,000円',
         membershipFee: {
           '週4〜5回': '36,000円',
         },
         privateLessonFee: '1時間 5,500円',
         hittingLessonFee: '1時間 3,000円',
       },
       training: {
         title: 'フィジカルトレーニング',
         // membershipFee: {
         //   '1回ごと': '500円',
         // },
         note: '参加1回ごとに500円',
       },
     },
   },
   adult: {                                   // 一般
     title: '一般クラス料金表 (消費税別)',
     notes: [
       '月4回受講の場合、雨天、試合の引率等による中止の場合は振替をお願いします。振替は月をまたいでも大丈夫です。',
       '第5週はお休みです。プライベートレッスンおよびヒッティングは受け付けます。',
     ],
     prices: {
       all: {
         title: '初心者、初中級、中上級、上級',
         time: '1時間20分、上級 1時間30分',
         initialFee: '5,000円',
         membershipFee: {
           'デイクラス 週1回 全4回': '7,500円',
           'ナイターおよび土曜日クラス 週1回 全4回': '8,500円',
           'フリーレッスン 有効期限3ヶ月 全6回': '14,000円',
         },
         privateLessonFee: '1時間 6,000円',
         hittingLessonFee: '30分 2,000円',
         note: '人数が一人の場合40分、二人の場合60分のレッスンになります。',
       },
     },
   },
  }

}
